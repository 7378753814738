#Connect{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#Connect h1{
    margin-bottom: 40px;
    color: #CB997E;
    font-weight: 900;
}
#Connect #link-container{
    display: flex;
    flex-direction: column;
}
#Connect a{
    width: 180px;
    padding: 10px;
    margin: 8px;
    background-color: #fefefe;
    border-radius:1vw;
    color: #6B705C;
    text-align: center;
    font-size: larger;
    font-weight: bolder;
    text-decoration: none;
}

@media all and (min-width: 880px){
    #Connect #link-container{
        flex-direction: row;
    }
}