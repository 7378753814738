#About{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
#About .scroll{
    overflow: scroll;
}
#About #main{
    margin-top: calc(100px - 2vw);
    padding-left: 15vw;
    padding-right: 15vw;
}