#Welcome{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#Welcome #info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#Welcome #name-image-container{
    display: flex;
    align-items: center;
}
#Welcome #name-container{
    display: flex;
    flex-direction: column;
}
#Welcome #link-container{
    display: flex;
    flex-direction: column;
}
#Welcome a{
    text-align: center;
    background-color: #DDBEA9;
    color: black;
    border-radius:1vw;
    width: 180px;
    padding: 5px;
    margin: 8px;
    text-decoration: none;
}
#Welcome a:hover{
    color: white;
    cursor: pointer;
}
#Welcome a:active{
    color: #FFE8D6
}
#Welcome h1{
    color: #6B705C;
    font-size: calc(40px + 1.5vw);
    font-weight: bold;
}
#Welcome h2{
    font-size: 20px;
}
#Welcome img{
    width: calc(70px + 1vw);
    padding: 5px;
}

@media all and (min-width: 700px){
    #Welcome #link-container{
        flex-direction: row;
    }
}